<template>
  <div class="summaryContainer">
    <div class="summaryHeaderWrapper">
      <span>Congratulations!</span>
      <span>Your customized furniture options are in the works.</span>
    </div>
    <div class="info">As a reminder, here’s a summary of your {{ responses.type_of_furniture.replace(/[-_]/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }} selections:</div>
    <div class="divider wide"/>
    <span class="label">Timeline:</span>
    <div class="info">{{ responses.your_timeline === "0" ? "I'm in no real rush" : "I needed my furniture yesterday" }}</div>
    <div class="divider"/>
    <span class="label">Style choice:</span>
    <div class="info capitalized"> {{ responses.favorite_styles.join(" ,") }} </div>
    <div class="divider"/>
    <span class="label">Furniture preferences:</span>

    <!-- Living room furnitures -->
    <div class="sectionWrapper" v-if="responses.type_of_furniture === 'living-room'">
      <!-- Sofa -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('sofa')">
        <span class="header">Sofa</span>
        <div class="">
          <span class="budgetLabel">{{ responses.furnitures.sofa.budget }}: {{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.sofa.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.sofa_fabric_options.inputFields.find(el => el.value === responses.furnitures.sofa.materials).value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.sofa_type_options.find(el => el.value === responses.furnitures.sofa.type)?.imagePublicID" />
            {{ quizData.sofa_type_options.find(el => el.value === responses.furnitures.sofa.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Chair -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('lounge_chair')">
        <span class="header">Chair</span>
          <span class="budgetLabel">{{ responses.furnitures.lounge_chair.budget }}: {{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.lounge_chair.budget).info }}</span>
        <div class="fabricInfo">
          {{ quizData.chair_fabric_options.inputFields.find(el => el.value === responses.furnitures.lounge_chair.materials).value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chair_type_options.find(el => el.value === responses.furnitures.lounge_chair.type)?.imagePublicID" />
            {{ quizData.chair_type_options.find(el => el.value === responses.furnitures.lounge_chair.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Sideboard -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('sideboard')">
        <span class="header">Sideboard</span>
          <span class="budgetLabel">{{ responses.furnitures.sideboard.budget }}: {{ quizData.sideboard_budget_options.inputFields.find(budget => budget.value === responses.furnitures.sideboard.budget).info }}</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.sideboard_type_options.find(el => el.value === responses.furnitures.sideboard.type)?.imagePublicID" />
            {{ quizData.sideboard_type_options.find(el => el.value === responses.furnitures.sideboard.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Coffee table -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('coffee_table')">
        <span class="header">Coffee table</span>
          <span class="budgetLabel">{{ responses.furnitures.coffee_table.budget }}: {{ quizData.coffee_budget_options.inputFields.find(budget => budget.value === responses.furnitures.coffee_table.budget).info }}</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.coffee_type_options.find(el => el.value === responses.furnitures.coffee_table.type)?.imagePublicID" />
            {{ quizData.coffee_type_options.find(el => el.value === responses.furnitures.coffee_table.type)?.label }}
          </div>
          <div v-for="(surface, surfaceIndex) in responses.furnitures.coffee_table.surfaces" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.coffee_surface_options.inputFields.find(el => el.value === surface)?.imagePublicID" />
            {{ quizData.coffee_surface_options.inputFields.find(el => el.value === surface)?.text }}
          </div>
          <div class="furniture" v-for="(shape, shapeIndex) in responses.furnitures.coffee_table.shapes" :key="shapeIndex">
            <CloudinaryImage :public-id="quizData.coffee_shape_options.inputFields.find(el => el.value === shape)?.imagePublicID" />
            {{ quizData.coffee_shape_options.inputFields.find(el => el.value === shape)?.text }}
          </div>
        </div>
      </div>
      <!-- End table -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('end_table')">
        <span class="header">End table</span>
          <span class="budgetLabel">{{ responses.furnitures.end_table.budget }}: {{ quizData.end_table_budget_options.inputFields.find(budget => budget.value === responses.furnitures.end_table.budget).info }}</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.end_table_type_options.find(el => el.value === responses.furnitures.end_table.type)?.imagePublicID" />
            {{ quizData.end_table_type_options.find(el => el.value === responses.furnitures.end_table.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Rug -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('rug')">
        <span class="header">Rug</span>
          <span class="budgetLabel">{{ responses.furnitures.rug.budget }}: {{ quizData[`rug_budget_${responses.furnitures.rug.shape}_options`]?.inputFields.find(
                budget => budget.value === responses.furnitures.rug.budget
                )?.info }}</span>
        <div class="selectedOptions">
          <div v-for="(style, surfaceIndex) in responses.furnitures.rug.sub_type" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.rug_style_options.inputFields.find(el => el.value === style)?.imagePublicID" />
          </div>
        </div>
      </div>
      <!-- Color -->
      <div class="section">
        <span class="header">Color</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.living_room_colors_options.find(el => el.value === responses.colorIntensity)?.imagePublicID" />
            {{ quizData.living_room_colors_options.find(el => el.value === responses.colorIntensity)?.label }}
          </div>
        </div>
      </div>
    </div>
    <!-- Bedroom furnitures -->
    <div v-else-if="responses.type_of_furniture === 'bedroom'">
      <!-- Bed -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('bed')">
        <span class="header">Bed</span>
          <span class="budgetLabel">{{ responses.furnitures.bed.budget }}: {{ quizData.bed_budget_options.inputFields.find(budget => budget.value === responses.furnitures.bed.budget).info }}</span>
        <div class="fabricInfo" v-if="responses.furnitures.bed?.materials">
          {{ quizData.bed_fabric_options.inputFields.find(el => el.value === responses.furnitures.bed.materials).value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.bed_type_options.find(el => el.value === responses.furnitures.bed.type)?.imagePublicID" />
            {{ quizData.bed_type_options.find(el => el.value === responses.furnitures.bed.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Dresser -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('dresser')">
        <span class="header">Dresser</span>
          <span class="budgetLabel">{{ responses.furnitures.dresser.budget }}: {{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.dresser.budget).info }}</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.dresser_type_options.find(el => el.value === responses.furnitures.dresser.type)?.imagePublicID" />
            {{ quizData.dresser_type_options.find(el => el.value === responses.furnitures.dresser.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Nightstand -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('nightstand')">
        <span class="header">Nightstand</span>
          <span class="budgetLabel">{{ responses.furnitures.nightstand.budget }}: {{ quizData.nightstand_budget_options.inputFields.find(budget => budget.value === responses.furnitures.nightstand.budget).info }}</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.nightstand_type_options.find(el => el.value === responses.furnitures.nightstand.type)?.imagePublicID" />
            {{ quizData.nightstand_type_options.find(el => el.value === responses.furnitures.nightstand.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Chair -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('lounge_chair')">
        <span class="header">Chair</span>
          <span class="budgetLabel">{{ responses.furnitures.lounge_chair.budget }}: {{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.lounge_chair.budget).info }}</span>
        <div class="fabricInfo">
          {{ quizData.chair_fabric_options.inputFields.find(el => el.value === responses.furnitures.lounge_chair.materials).value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chair_bedroom_type_options.find(el => el.value === responses.furnitures.lounge_chair.type)?.imagePublicID" />
            {{ quizData.chair_bedroom_type_options.find(el => el.value === responses.furnitures.lounge_chair.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Bench -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('bench')">
        <span class="header">Bench</span>
          <span class="budgetLabel">{{ responses.furnitures.bench.budget }}: {{ quizData.bench_budget_options.inputFields.find(budget => budget.value === responses.furnitures.bench.budget).info }}</span>
        <div class="fabricInfo">
          {{ quizData.bench_fabric_options.inputFields.find(el => el.value === responses.furnitures.bench.materials).value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.bench_type_options.find(el => el.value === responses.furnitures.bench.type)?.imagePublicID" />
            {{ quizData.bench_type_options.find(el => el.value === responses.furnitures.bench.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Rug -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('rug')">
        <span class="header">Rug</span>
          <span class="budgetLabel">{{ responses.furnitures.rug.budget }}: {{ quizData[`rug_budget_${responses.furnitures.rug.shape}_options`]?.inputFields.find(
                budget => budget.value === responses.furnitures.rug.budget
                )?.info }}</span>
        <div class="selectedOptions">
          <div v-for="(style, surfaceIndex) in responses.furnitures.rug.sub_type" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.rug_style_options.inputFields.find(el => el.value === style)?.imagePublicID" />
          </div>
        </div>
      </div>
      <!-- Color -->
      <div class="section">
        <span class="header">Color</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.bedroom_colors_options.find(el => el.value === responses.colorIntensity)?.imagePublicID" />
            {{ quizData.bedroom_colors_options.find(el => el.value === responses.colorIntensity)?.label }}
          </div>
        </div>
      </div>
    </div>
    <!-- Dining Room furnitures -->
    <div v-if="responses.type_of_furniture === 'dining-room'">
      <!-- Table -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('dining_table')">
        <span class="header">Table</span>
          <span class="budgetLabel">{{ responses.furnitures.dining_table.budget }}: {{ quizData.dining_table_budget_options.inputFields.find(budget => budget.value === responses.furnitures.dining_table.budget).info }}</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.table_type_options.find(el => el.value === responses.furnitures.dining_table.type)?.imagePublicID" />
            {{ quizData.table_type_options.find(el => el.value === responses.furnitures.dining_table.type)?.label }}
          </div>
          <!-- <div v-for="(shape, shapeIndex) in responses.furnitures.dining_table.shape" :key="shapeIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.table_shape_options.inputFields.find(el => el.value === shape)?.imagePublicID" />
            {{ quizData.table_shape_options.inputFields.find(el => el.value === shape)?.text }}
          </div> -->
        </div>
      </div>
      <!-- Chairs -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('dining_chair')">
        <span class="header">Chairs</span>
          <span class="budgetLabel">{{ responses.furnitures.dining_chair.budget }}: {{ quizData.dining_chairs_budget_options.inputFields.find(budget => budget.value === responses.furnitures.dining_chair.budget).info }}</span>
        <div class="fabricInfo">
          {{ quizData.chairs_fabric_options.inputFields.find(el => el.value === responses.furnitures.dining_chair.materials)?.value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chairs_type_options.find(el => el.value === responses.furnitures.dining_chair.type)?.imagePublicID" />
            {{ quizData.chairs_type_options.find(el => el.value === responses.furnitures.dining_chair.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Stools -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('stool')">
        <span class="header">Stools</span>
          <span class="budgetLabel">{{ responses.furnitures.stool.budget }}: {{ quizData.stools_budget_options.inputFields.find(budget => budget.value === responses.furnitures.stool.budget).info }}</span>
        <div class="fabricInfo">
          {{ quizData.stools_fabric_options.inputFields.find(el => el.value === responses.furnitures.stool.materials)?.value }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.stools_type_options.find(el => el.value === responses.furnitures.stool.type)?.imagePublicID" />
            {{ quizData.stools_type_options.find(el => el.value === responses.furnitures.stool.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Rug -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('rug')">
        <span class="header">Rug</span>
          <span class="budgetLabel">{{ responses.furnitures.rug.budget }}: {{ quizData[`rug_budget_${responses.furnitures.rug.shape}_options`]?.inputFields.find(
                budget => budget.value === responses.furnitures.rug.budget
                )?.info }}</span>
        <div class="selectedOptions">
          <div v-for="(style, surfaceIndex) in responses.furnitures.rug.sub_type" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.rug_style_options.inputFields.find(el => el.value === style)?.imagePublicID" />
          </div>
        </div>
      </div>
      <!-- Color -->
      <div class="section">
        <span class="header">Color</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.dining_room_colors_options.find(el => el.value === responses.colorIntensity)?.imagePublicID" />
            {{ quizData.dining_room_colors_options.find(el => el.value === responses.colorIntensity)?.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="congratulationsBottomText">
     Now let's get started on your next room!
    </div>
    <div class="customActions">
      <router-link to="/projects">
        <button class="btn-Border summaryButton">
          Not now
        </button>
      </router-link>
      <router-link to="/quiz">
        <button class="customGreenBTN summaryButton">
          I'm ready, let's do it
        </button>
      </router-link>
    </div>
    <div class="quizNavigationWrapper">
    <button
    @click="back"
    class="quiz__navigation__button quiz__navigation__button--back"
  >
    <span class="icon-arrow-left"></span> Back
   </button>
  </div>
  </div>
</template>

<script>

import * as quizData from '@/components/quiz/quizDataNew'
import CloudinaryImage from '@/components/CloudinaryImage.vue'
import { boot } from '@intercom/messenger-js-sdk'
import { mapGetters } from 'vuex'

export default {
  name: 'SurveySummary',
  created () {
    if (this.$route.query.data) {
      try {
        const decodedData = atob(this.$route.query.data)
        this.responses = JSON.parse(decodedData)
      } catch (e) {
        console.error('Error parsing data from URL:', e)
      }
    }
  },
  data () {
    return {
      responses: {},
      quizData,
      replaceFurnitures: []
    }
  },
  watch: {
    responses: {
      handler (newResponses) {
        this.replaceFurnitures = newResponses?.replace_furnitures
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    CloudinaryImage
  },
  async mounted () {
    if (this.$gtm?.enabled()) {
      this.$gtm.trackEvent({
        event: 'quiz_step_view',
        section: 'Summary'
      })
    }
    boot({
      app_id: 'rokhj26f',
      user_id: this.userProfile.firestore_reference_userId,
      name: this.userProfile.displayname,
      email: this.userProfile.email_address,
      created_at: this.userProfile.date_createdAt
    })
  },
  unmounted () {
    if (window.Intercom) {
      window.Intercom('shutdown')
    }
  },
  computed: {
    ...mapGetters(['userProfile'])
  },
  methods: {
    back () {
      this.$router.push({
        path: '/quiz',
        query: {
          id: this.responses.surveyId,
          completed: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.summaryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 2.5rem;
  padding: 2.5rem 1.25rem 1.25rem 1.25rem;
  font-family: "Sohne";
  font-size: 1rem;
  color: #0b1326;
}

.summaryHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'League Spartan';
  color: #004021;
  margin-bottom: 16px;
}

.summaryHeaderWrapper :first-child {
  font-size: 2rem !important;
  font-weight: 700;
}

.summaryHeaderWrapper :nth-child(2) {
  font-size: 1.125rem !important;
  font-weight: 500;
  color: #0b1326;
  font-family: "Sohne";
  margin-top: 16px;
  text-align: center;
}

.sectionWrapper{
  width:100%;
  @media (min-width: 768px) {
    width:50%;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  border-bottom: 1px solid #CED7D2;
  padding: 24px 0;
  width: 100%;
}

.selectedOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.furniture {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  font-weight: 400;
  font-size: 0.875rem;

  &::after{
    content: 'Sample';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 20px));
    font-size: 32px;
    font-weight: 700;
    color: #004021;
    opacity: 0.5;
  }
}

.furniture img {
  max-height: 200px
}

.budgetInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.budgetLabel {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
}

.budgetRange {
  font-weight: 400;
}

.timeline {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1.25rem;
}

.header {
  font-family: 'Sohne';
  font-size: 1rem;
  font-weight: 600;
  margin: 8px 0;
}

.info {
  font-family: "Sohne";
  color: #0b1326;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  padding: 0 24px;
  text-align: center;
}
.capitalized{
  text-transform: capitalize;
}

.fabricInfo{
  font-family: "Sohne";
  color: #0b1326;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  margin-top: 8px;
  text-transform: capitalize;
}

.congratulationsBottomText {
  font-family: 'League Spartan';
  font-size: 32px;
  font-weight: 600;
  color: #004021;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  text-align: center;
}

.customActions {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 0.625rem;
  margin-top: 0.9375rem;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
  }
}

.selectedStyles {
  font-size: 1.5rem;
  font-weight: 300;
}

.timelineWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5625rem 0 2.1875rem 0;
  border: none;
  padding: 0;
  max-width: 37.5rem;
}

.styleChoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.9375rem 0 2.1875rem 0;
  border: none;
  padding: 0;
}

.divider{
  width: 100%;
  background-color: #CED7D2;
  height: 1px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    width:50%;
  }
}

.wide{
  width: 100%;
}

.label{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px ;
}

.summaryButton{
  min-width: 272px;
}

.quizNavigationWrapper {
    position: sticky;
    bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    width: 100%;

    @media (max-width: 1080px) {
      justify-content: center;
      margin-top: 24px;
  }

  }

  .quiz__navigation__button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5em 1em;
    border: none;
    cursor: pointer;
  }

  .quiz__navigation__button--back {
    background-color: #f0f0f0;
    font-weight: 500;
    height: 38px;
    text-transform: capitalize;
    font-family: 'Sohne';

    @media (max-width: 1080px) {
    width: 272px;
  }

  }

</style>
